import { PICTURE_PROP_TYPES } from 'constants/prop-types'

import React, { memo } from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  Quote,
  Picture,
  FullName,
  JobTitle,
} from './testimonial-module.styles'

const TestimonialModule = ({ quote, picture, fullName, jobTitle, ...rest }) => {
  const {
    src,
    loading,
    decoding,
    fetchpriority,
    role = 'presentation',
    alt,
  } = picture
  const textColor = rest?.style?.color
  const quoteStyle = {}
  if (textColor) {
    quoteStyle.color = textColor
  }
  return (
    <Container {...rest}>
      <Quote style={quoteStyle}>{quote}</Quote>
      <Picture
        alt={alt}
        role={role}
        src={src}
        loading={loading}
        decoding={decoding}
        fetchpriority={fetchpriority}
      />
      <FullName>{fullName}</FullName>
      <JobTitle>{jobTitle}</JobTitle>
    </Container>
  )
}

TestimonialModule.displayName = 'TestimonialModule'

TestimonialModule.propTypes = {
  quote: PropTypes.string.isRequired,
  picture: PropTypes.shape(PICTURE_PROP_TYPES).isRequired,
  fullName: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
}

export default memo(TestimonialModule)
