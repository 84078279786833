import { fontSize, fontWeight, lineHeight } from 'constants/typography'
import mediaQueries from 'util/mediaqueries'

import styled from 'styled-components'
import GridContainer from 'components/grid-container'
import { Headline } from 'components/typography'
import { ImageWithLazyAttributes } from 'components/images'

export const Container = styled(GridContainer)`
  justify-items: center;
  text-align: center;
  padding: 64px 0;
`

export const Quote = styled(Headline)`
  grid-column: 1 / -1;
  margin-bottom: 40px;

  ${mediaQueries.sm`
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.x2Large};
    line-height: ${lineHeight.xSmall};
  `};

  &::before {
    content: '\u201C';
  }

  &::after {
    content: '\u201D';
  }

  ${mediaQueries.md`
    grid-column: 2 / -2;
  `};

  ${mediaQueries.lg`
    grid-column: 3 / -3;
  `};
`

export const Picture = styled(ImageWithLazyAttributes)`
  grid-column: 1 / -1;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  margin-bottom: 16px;

  ${mediaQueries.sm`
    width: 80px;
    height: 80px;
  `};
`

export const FullName = styled.span`
  grid-column: 1 / -1;
  font-weight: ${fontWeight.bold};
  font-size: ${fontSize.small};
  line-height: ${lineHeight.large};
`

export const JobTitle = styled.span`
  grid-column: 1 / -1;
  font-weight: ${fontWeight.regular};
  font-size: ${fontSize.small};
  line-height: ${lineHeight.large};
`
